@import "normalize.css";

body {
  overflow: hidden;
}

.platypus-enter {
  font-size: 3em;
}

.platypus-root {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;

  width: 100vw;
  text-align: center;

  .header {
    .title {
      font-size: 3em;
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8vw;

    h1 {
      padding: 0;
      margin: 0;
      animation: overlay-text 1s linear 4s 1 normal forwards;
    }

    animation: overlay 2s linear 4s 1 normal forwards;

    opacity: 0;

    color: #fff;
    background-color: rgba(#000000, 0.3);

    text-shadow: #000 0px 0px 30px;

    @keyframes overlay-text {
      0% {
        transform: scale(0);
      }

      100% {
        transform: scale(1);
      }
    }

    @keyframes overlay {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }
  }

  .platypus-player {
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 1.5s ease 7;
    animation-fill-mode: forwards;

    @keyframes gradient {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }
  }

  .platypuss {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex-wrap: wrap;
    flex-direction: column;

    @media (orientation: landscape) {
      flex-direction: row;
    }

    > img {
      display: block;
      min-width: 200px;
    }
  }

  .platypus {
    animation-name: rotate;
    animation-direction: normal;
    animation-duration: 8s;
    animation-fill-mode: forwards;
    animation-play-state: running;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;

    max-width: 20vw;

    @keyframes rotate {
      0% {
        transform: rotate(0deg) scale(1);
      }

      100% {
        transform: rotate(360deg) scale(3);
      }
    }
  }

  .replay {
    cursor: pointer;
    position: fixed;
    z-index: 9999;
    padding: 1rem;
    background-color: #000;
    color: #fff;
    bottom: 10vh;
    font-size: 2rem;
    border-radius: 16px;
    box-shadow: 1px 1px 10px #000;
  }
}
